
@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);

html, body, html * {
  font-family: 'Roboto', sans-serif;
}

h2 {
	margin: 0;
}

.me {
	position: absolute;
	display:  block;
	min-width: 300px;
	max-width: 400px;
	width: auto;
	height: auto;
	top: 25%;
	left: 50%;
  transform: translate(-50%, -25%);
}

.paper {
	background-color: #eee;
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  padding:  20px;
}

.link-btn {
	width: 100%;
	height: 34px;
	line-height: 34px;
	border-radius: 4px;
	background-color: #e6e6e6;
  color: #222;
  border:  none;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.link-btn:hover {
	cursor: pointer;
}


